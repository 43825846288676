import React from "react";
import SEO from "../components/seo";
import Header from "../components/header";
import Section from "../components/section";
import ServiceBar from "../components/serviceBar";
import Layout from "../components/layout";
import H1 from "../components/sectionHeading";
import { Row, Col } from "react-bootstrap";

const Services = () => {
  return (
  <Layout>
    <SEO title="Checkout - Thank You" path='checkout-success'/>
    
    <Header/>

    <Section className='cg-section-light' nohr>
      <Row>
        <Col>
          <H1>Thank You</H1>
          <p>Thanks for your purchase. We'll be in contact with you soon to fulfill your order.</p>
        </Col>
      </Row>
    </Section>
    
    <ServiceBar />
    
  </Layout>
)};

export default Services;
